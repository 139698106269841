import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Posts from 'components/Posts';
import { PageProps } from 'gatsby';

const BlogPage: React.FC<PageProps> = (   pageProps ) => {
  return (
    <Layout location={pageProps.location}>
      <SEO title="Blog" />
      <Posts />
    </Layout>
  );
};

export default BlogPage;
